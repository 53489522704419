import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Typography, Box, Card, CardContent, Grid, Paper, CircularProgress, Button } from '@mui/material';
import { Line } from 'react-chartjs-2';
import { useFetchStudentStatsQuery } from '../../redux/services/api'; // Import the hook
import {
  Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend,
} from 'chart.js';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SchoolIcon from '@mui/icons-material/School';
import StarIcon from '@mui/icons-material/Star';
import MoodIcon from '@mui/icons-material/Mood';
import DoneAllIcon from '@mui/icons-material/DoneAll';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const StudentDashboard = () => {
  const studentId = localStorage.getItem('studentId');

  // Use the RTK Query hook to fetch student stats
  const { data: stats, isLoading, error } = useFetchStudentStatsQuery(studentId);

  // Sample data for chart
  const data = {
    labels: ['March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Courses Completed',
        data: [2, 4, 1, 3],
        borderColor: '#90caf9',
        backgroundColor: 'rgba(144, 202, 249, 0.2)',
        borderWidth: 2,
        tension: 0.3,
        pointBackgroundColor: '#1565c0',
        pointBorderColor: '#fff',
        pointRadius: 5,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        labels: {
          color: '#3f51b5',
          font: {
            size: 14,
            weight: 'bold',
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#616161',
        },
        grid: {
          color: 'rgba(0,0,0,0.1)',
        },
      },
      y: {
        ticks: {
          color: '#616161',
        },
        grid: {
          color: 'rgba(0,0,0,0.1)',
        },
      },
    },
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#3f51b5', textAlign: 'center', fontWeight: 'bold' }}>
        🎉 Welcome to Your Oracy Dashboard! 🎉
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'center', color: '#616161' }}>
        Track your progress and skills as you enhance your oracy journey!
      </Typography>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <Typography variant="h6" color="error">
            Error fetching stats. Please try again later.
          </Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ backgroundColor: '#e3f2fd', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#1565c0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <SchoolIcon fontSize="large" sx={{ mr: 1 }} /> Total Courses
                  </Typography>
                  <Typography variant="h4" sx={{ color: '#1565c0' }}>
                    {stats?.totalCourses || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ backgroundColor: '#f1f8e9', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#2e7d32', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <EmojiEventsIcon fontSize="large" sx={{ mr: 1 }} /> Completed Courses
                  </Typography>
                  <Typography variant="h4" sx={{ color: '#2e7d32' }}>
                    {stats?.completedCourses || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ backgroundColor: '#fff3e0', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#ef6c00', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <StarIcon fontSize="large" sx={{ mr: 1 }} /> Total Assignments
                  </Typography>
                  <Typography variant="h4" sx={{ color: '#ef6c00' }}>
                    {stats?.totalAssignments || 0}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ backgroundColor: '#ede7f6', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#5e35b1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <DoneAllIcon fontSize="large" sx={{ mr: 1 }} /> Oracy Level
                  </Typography>
                  <Typography variant="h4" sx={{ color: '#5e35b1' }}>
                    {stats?.oracyLevel || 'Beginner'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Card sx={{ backgroundColor: '#fce4ec', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
                <CardContent>
                  <Typography variant="h6" sx={{ color: '#d81b60', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <MoodIcon fontSize="large" sx={{ mr: 1 }} /> Quick Actions
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                    <Button variant="contained" color="primary" sx={{ mb: 1, width: '200px' }} component={NavLink} to="/courses">
                      🏫 View Courses
                    </Button>
                    <Button variant="contained" color="secondary" sx={{ width: '200px' }} component={NavLink} to="/assignments">
                      📚 View Assignments
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Chart Section */}
          <Box mt={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ color: '#3f51b5', textAlign: 'center', mb: 2 }}>
              📈 Your Activity Overview
            </Typography>
            <Paper
              sx={{
                width: { xs: '100%', md: '80%' },
                height: '400px',
                padding: '16px',
                borderRadius: '12px',
                boxShadow: 1,
              }}
            >
              <Line data={data} options={chartOptions} />
            </Paper>
          </Box>

          {/* Motivational Quote Section */}
          <Box mt={6} textAlign="center">
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2e7d32' }}>
              🌟 "Keep going! Every step forward is a step closer to success!" 🌟
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default StudentDashboard;
