import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import StudentDashboard from './components/student/Dashboard';
import TeacherDashboard from './components/teacher/TeacherDashboard';
import Students from './components/admin/Students';
import Teachers from './components/admin/Teachers';
import Courses from './components/admin/Courses';
import Dashboard from './components/admin/Dashboard';
import { Discussions } from './components/Discussions';
import Layout from './components/Layout';
import Calendar from './components/Calendar';
import Profile from './components/Profile';
import Setting from './components/Setting'; 
import Users from './components/admin/Users';
import Notice from './components/Notice';
import Complaint from './components/Complaint';
import DefaultPage from './components/DefaultPage';
import ProtectedRoute from './components/ProtectedRoute';
import MyTeachers from './components/student/MyTeachers';
import StudentComplaint from './components/student/StudentComplaints'
import StudentCourses from './components/student/MyCourses';
import TeacherCourses from './components/teacher/MyCourses';
import MyStudents from './components/teacher/MyStudents';
import TeacherComplaint from './components/teacher/TeacherComplaints';
import TeacherProfile from './components/teacher/TeacherProfile';
import StudentProfile from './components/student/StudentProfile';
import AssignCourses from './components/admin/AssignCourses';
import NotFound from './components/NotFound';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<DefaultPage />} />
        <Route path="/admin" element={<Layout />}>
          <Route path="dashboard" element={<ProtectedRoute element={<Dashboard />} allowedRoles={[1]} />} />
          <Route path="users" element={<ProtectedRoute element={<Users />} allowedRoles={[1]} />} />
          <Route path="students" element={<ProtectedRoute element={<Students />} allowedRoles={[1]} />} />
          <Route path="student/assign-courses/:studentId"  element={<ProtectedRoute element={<AssignCourses />} allowedRoles={[1]} />} />
          <Route path="teachers" element={<ProtectedRoute element={<Teachers />} allowedRoles={[1]} />} />
          <Route path="courses" element={<ProtectedRoute element={<Courses />} allowedRoles={[1]} />} />
          <Route path="notice" element={<ProtectedRoute element={<Notice />} allowedRoles={[1]} />} />
          {/* <Route path="calendar" element={<ProtectedRoute element={<Calendar />} allowedRoles={[1]} />} /> */}
          <Route path="setting" element={<ProtectedRoute element={<Setting />} allowedRoles={[1]} />} />
          <Route path="profile" element={<ProtectedRoute element={<Profile />} allowedRoles={[1]} />} />
          <Route path="complaints" element={<ProtectedRoute element={<Complaint />} allowedRoles={[1]} />} />
          <Route path="discussion" element={<ProtectedRoute element={<Discussions />} allowedRoles={[1]} />} />
        </Route>

        {/* Teacher Dashboard */}
       <Route path="/teacher" element={<Layout />}>
          <Route path="dashboard" element={<ProtectedRoute element={<TeacherDashboard />} allowedRoles={[2]} />} /> 
           <Route path="courses" element={<ProtectedRoute element={<TeacherCourses />} allowedRoles={[2]} />} />
          <Route path="students" element={<ProtectedRoute element={<MyStudents />} allowedRoles={[2]} />} />
          <Route path="notice" element={<ProtectedRoute element={<Notice />} allowedRoles={[2]} />} />
          {/* <Route path="calendar" element={<ProtectedRoute element={<Calendar />} allowedRoles={[2]} />} /> */}
          <Route path="complaints" element={<ProtectedRoute element={<TeacherComplaint />} allowedRoles={[2]} />} />
          <Route path="profile" element={<ProtectedRoute element={<TeacherProfile />} allowedRoles={[2]} />} /> 
        </Route>

        {/* Student Dashboard */}
        <Route path="/student" element={<Layout />}> 
          <Route path="dashboard" element={<ProtectedRoute element={<StudentDashboard />} allowedRoles={[3]} />} /> 
          <Route path="teachers" element={<ProtectedRoute element={<MyTeachers />} allowedRoles={[3]} />} />
          <Route path="notice" element={<ProtectedRoute element={<Notice />} allowedRoles={[3]} />} />
          <Route path="complaints" element={<ProtectedRoute element={<StudentComplaint />} allowedRoles={[3]} />} />
          <Route path="courses" element={<ProtectedRoute element={<StudentCourses />} allowedRoles={[3]} />} />
          {/* <Route path="calendar" element={<ProtectedRoute element={<Calendar />} allowedRoles={[3]} />} /> */}
          <Route path="profile" element={<ProtectedRoute element={<StudentProfile />} allowedRoles={[3]} />} />  
        </Route>
        <Route path='*' element={<NotFound />}/>
      </Routes>
    </Router>
  );
}

export default App;
