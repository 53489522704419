// redux/reducers/complaintReducer.js
import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Async actions for fetching complaints
export const fetchComplaints = createAsyncThunk(
  'complaints/fetchComplaints',
  async () => {
    const response = await axios.get('https://server.oracy.uk/api/admin/complaints', { withCredentials: true });
    return response.data;
  }
);

// Async actions for approving a complaint
export const approveComplaint = createAsyncThunk(
  'complaints/approveComplaint',
  async (id) => {
    await axios.post(`https://server.oracy.uk/api/admin/complaints/${id}/approve`, { withCredentials: true });
    return id; 
  }
);

// Async actions for disapproving a complaint
export const disapproveComplaint = createAsyncThunk(
  'complaints/disapproveComplaint',
  async (id) => {
    await axios.post(`https://server.oracy.uk/api/admin/complaints/${id}/disapprove`, { withCredentials: true });
    return id; 
  }
);


const initialState = {
  complaints: [],
  loading: false,
  error: null
};

const complaintSlice = createSlice({
  name: 'complaints',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch complaints
      .addCase(fetchComplaints.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchComplaints.fulfilled, (state, action) => {
        state.loading = false;
        state.complaints = action.payload;
      })
      .addCase(fetchComplaints.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(approveComplaint.fulfilled, (state, action) => {
        state.complaints = state.complaints.filter(
          (complaint) => complaint.id !== action.payload
        );
      })
      .addCase(disapproveComplaint.fulfilled, (state, action) => {
        state.complaints = state.complaints.filter(
          (complaint) => complaint.id !== action.payload
        );
      });
  }
});

export default complaintSlice.reducer;
