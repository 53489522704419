import React, { useState } from 'react';
import { Box, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, Button, TextField, MenuItem, CircularProgress, Snackbar, Alert } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const Settings = () => {
  const [fileType, setFileType] = useState('json');
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [localError, setLocalError] = useState(null);

  // Function to handle exporting data
  const handleExportData = async () => {
    setIsLoading(true);  // Start the loading indicator

    try {
      const response = await axios.get('https://server.oracy.uk/api/admin/export', {
        params: { fileType },
        withCredentials: true,
        responseType: 'blob',  
        headers: {
          'Accept': fileType === 'csv' ? 'text/csv' : 'application/json',
        },
      });

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: 'text/csv' });  // For CSV files
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `user_data.${fileType === 'csv' ? 'csv' : 'json'}`); // Dynamic filename based on fileType
      document.body.appendChild(link);
      link.click();
      link.remove();

      setSuccess('Data exported successfully');  // Show success message
    } catch (err) {
      console.error('Error exporting data:', err);
      setLocalError('Failed to export data');  // Show error message
    } finally {
      setIsLoading(false);  // Stop the loading indicator
    }
  };

  return (
    <Box className="settings-tab-container" style={{ padding: '20px' }}>
      <Typography variant="h4" gutterBottom>Settings</Typography>
      <Typography variant="subtitle1" gutterBottom>
        Manage your dashboard preferences and system settings.
      </Typography>
      <Divider style={{ marginBottom: '20px' }} />

      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">General Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography variant="subtitle1">Language</Typography>
            <TextField select fullWidth margin="normal" defaultValue="English">
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Spanish">Spanish</MenuItem>
              <MenuItem value="French">French</MenuItem>
            </TextField>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Data & Backup</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Typography variant="subtitle1">Backup Frequency</Typography>
            <TextField select fullWidth margin="normal" defaultValue="Daily">
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
              <MenuItem value="Monthly">Monthly</MenuItem>
            </TextField>
            <Typography variant="subtitle1">Export Data</Typography>
            <TextField
              select
              fullWidth
              margin="normal"
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
            >
              <MenuItem value="json">JSON</MenuItem>
              <MenuItem value="csv">CSV</MenuItem>
            </TextField>
            <Button variant="outlined" color="primary" onClick={handleExportData} disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : 'Export Now'}
            </Button>
            <Typography variant="subtitle1">Import Data</Typography>
            <Button variant="outlined" color="primary">Import</Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />

      {/* Footer Section */}
      <Box display="flex" justifyContent="space-between">
        <Button variant="contained" color="primary">
          Save All Changes
        </Button>
        <Button variant="text" color="secondary">
          Revert to Default
        </Button>
      </Box>

      {/* Snackbar for messages */}
      <Snackbar open={!!localError || !!success} autoHideDuration={6000} onClose={() => { setSuccess(null); setLocalError(null); }}>
        <Alert onClose={() => { setSuccess(null); setLocalError(null); }} severity={localError ? "error" : "success"} sx={{ width: '100%' }}>
          {localError ? localError : success}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
