import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  Button, List, ListItem, ListItemText, IconButton, Typography, Paper, CircularProgress, Alert 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchNotices, addNotice, updateNotice, deleteNotice } from '../redux/slices/noticesSlice';
import NoticeDialog from './NoticeDialog';
import ConfirmDialog from './ConfirmDialog';

const Notice = () => {
  const dispatch = useDispatch();
  const { notices, isLoading, error } = useSelector((state) => state.notices);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [noticeToDelete, setNoticeToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchNotices());
    const role = localStorage.getItem('userRole');
    setUserRole(role);
  }, [dispatch]);

  const handleAddNotice = () => {
    setSelectedNotice(null);
    setIsEditing(false);
    setIsDialogOpen(true);
  };

  const handleEditNotice = (notice) => {
    setSelectedNotice(notice);
    setIsEditing(true);
    setIsDialogOpen(true);
  };

  const handleDeleteNotice = (notice) => {
    setNoticeToDelete(notice);
    setConfirmDialogOpen(true);
  };

  const confirmDeleteNotice = () => {
    if (noticeToDelete) {
      dispatch(deleteNotice(noticeToDelete.id));
    }
    setConfirmDialogOpen(false);
    setNoticeToDelete(null);
  };

  const handleSaveNotice = (noticeData) => {
    if (isEditing && selectedNotice) {
      dispatch(updateNotice({ id: selectedNotice.id, noticeData }));
    } else {
      dispatch(addNotice(noticeData));
    }
    setIsDialogOpen(false);
  };

  return (
    <div style={{ padding: '16px' }}>
      <Typography variant="h4" gutterBottom>
        Notices
      </Typography>

      {userRole === '1' && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNotice}
          sx={{ mb: 2 }}
        >
          Add Notice
        </Button>
      )}

      {isLoading ? (
        <CircularProgress sx={{ display: 'block', margin: '16px auto' }} />
      ) : error ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error || 'Failed to load notices. Please try again later.'}
        </Alert>
      ) : (
        <Paper elevation={3} sx={{ padding: 2 }}>
          {notices.length === 0 ? (
            <Typography variant="body1" color="text.secondary" textAlign="center">
              No notices available.
            </Typography>
          ) : (
            <List>
              {notices.map((notice) => (
                <ListItem
                  key={notice.id}
                  sx={{
                    mb: 2,
                    border: '1px solid #ddd',
                    borderRadius: 2,
                    padding: 2,
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="h6" color="text.primary">
                        {notice.title}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="text.secondary">
                        {notice.description}
                      </Typography>
                    }
                    sx={{ flexGrow: 1 }}
                  />
                  {userRole === '1' && (
                    <>
                      <IconButton edge="end" onClick={() => handleEditNotice(notice)}>
                        <EditIcon color="primary" />
                      </IconButton>
                      <IconButton edge="end" onClick={() => handleDeleteNotice(notice)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          )}
        </Paper>
      )}

      <NoticeDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onSave={handleSaveNotice}
        notice={selectedNotice}
        isEditing={isEditing}
      />

      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={confirmDeleteNotice}
        title="Confirm Delete"
        message="Are you sure you want to delete this notice?"
      />
    </div>
  );
};

export default Notice;
