import React from 'react';
import { useDeleteCourseMutation } from '../../redux/services/api';
import { Card, CardContent, CardActions, Typography, Button, Box, IconButton, Tooltip, CardMedia } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';

const CourseCard = ({ course, onEditCourse, onViewCourse }) => {
  const [deleteCourse] = useDeleteCourseMutation(); // Using the delete mutation hook

  const handleDelete = async (id) => {
    try {
      await deleteCourse(id);
      // Optionally, show a success message or perform any other action after deletion
    } catch (error) {
      console.error('Failed to delete course:', error);
    }
  };

  return (
    <Card sx={{ maxWidth: 345, mb: 2, boxShadow: 3, borderRadius: 2 }}>
      {/* Added CardMedia for the course image */}
      <CardMedia
        component="img"
        height="140"
        image={course.image ? `https://server.oracy.uk/uploads/${course.image}` : 'https://via.placeholder.com/345x140'} // Placeholder image if none provided
        alt={course.title}
      />
      <CardContent>
        <Typography variant="h6" component="div" gutterBottom>
          {course.title}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Instructor: {course.instructorId}
        </Typography>
        <Typography variant="body2" color="text.primary" paragraph>
          {course.description}
        </Typography>
        <Typography variant="body2" color={course.status === 'active' ? 'green' : 'red'} fontWeight="bold">
          Status: {course.status.charAt(0).toUpperCase() + course.status.slice(1)}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        <Box>
          <Tooltip title="View Course">
            <IconButton onClick={() => onViewCourse(course)} color="primary">
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Course">
            <IconButton onClick={() => onEditCourse(course)} color="secondary">
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Tooltip title="Delete Course">
          <IconButton onClick={() => handleDelete(course.id)} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  );
};

const CourseTable = ({ courses, onEditCourse, onViewCourse }) => {
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, pt: 2 }}>
      {courses.map((course) => (
        <CourseCard
          key={course.id}
          course={course}
          onEditCourse={onEditCourse}
          onViewCourse={onViewCourse}
        />
      ))}
    </Box>
  );
};

export default CourseTable;
