import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Box, Avatar, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useSelector, useDispatch } from 'react-redux';
import { resetUser } from '../redux/slices/roleSlice';
import axios from 'axios';

const Navbar = ({ drawerWidth, onDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.role.role); // Redux state for role

  const getProfileUrl = () => {
    switch (userRole) {
      case 1:
        return '/admin/profile';
      case 2:
        return '/teacher/profile';
      case 3:
        return '/student/profile';
      default:
        return '/';
    }
  };

  const getTitle = () => {
    switch (userRole) {
      case 1:
        return 'Admin Dashboard';
      case 2:
        return 'Teacher Dashboard';
      case 3:
        return 'Student Dashboard';
      default:
        return 'Default Page';
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.get('https://server.oracy.uk/auth/logout', { withCredentials: true });
      if (response.status === 200) {
        dispatch(resetUser());
        window.location.href = 'https://oracy.uk/';
      } else {
        alert('Error logging out');
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: '#3f51b5',
        color: 'white',
        boxShadow: 3,
        transition: 'all 0.3s ease-in-out',
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
          {getTitle()}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleProfileMenuOpen}
        >
          <Avatar alt="User Profile" src="" sx={{ bgcolor: '#fff', color: '#3f51b5' }} />
        </IconButton>
      </Toolbar>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleProfileMenuClose}
        sx={{
          mt: 2,
          '& .MuiPaper-root': {
            borderRadius: '8px',
            boxShadow: 3,
          }
        }}
      >
        <MenuItem component="a" href={getProfileUrl()}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default Navbar;
