import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography, CircularProgress, Button, Card, CardContent, CardActions } from '@mui/material';
import { fetchComplaints, approveComplaint, disapproveComplaint } from '../redux/slices/complaintSlice';

const Complaint = () => {
  const dispatch = useDispatch();
  const { complaints, loading, error } = useSelector((state) => state.complaints);

  const getUserRole = (role) => {
    switch (role) {
      case 2:
        return "Teacher";
      case 3:
        return "Student";
      default:
        return "Unknown Role";
    }
  };

  useEffect(() => {
    dispatch(fetchComplaints());
  }, [dispatch]);

  const handleApprove = (id) => {
    dispatch(approveComplaint(id));
  };

  const handleDisapprove = (id) => {
    dispatch(disapproveComplaint(id));
  };

  if (loading) {
    return (
      <Box sx={{ padding: 3, textAlign: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 3, textAlign: 'center' }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Complaints
      </Typography>
      <Grid container spacing={3}>
        {complaints.map((complaint) => (
          <Grid item xs={12} sm={6} md={4} key={complaint.id}>
            <Card variant="outlined" sx={{ height: '100%' }}>
              <CardContent>
                <Typography variant="h6">{complaint.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {complaint.description}
                </Typography>
                {/* Display User Name and Role */}
                {complaint.user ? (
                  <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
                    Submitted by: {complaint.user.name} (Role: {getUserRole(complaint.user.role)})
                  </Typography>
                ) : (
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                    Submitted by: Unknown User
                  </Typography>
                )}
              </CardContent>
              <CardActions>
                <Button 
                  size="small" 
                  color="success" 
                  onClick={() => handleApprove(complaint.id)}
                >
                  Approve
                </Button>
                <Button 
                  size="small" 
                  color="error" 
                  onClick={() => handleDisapprove(complaint.id)}
                >
                  Disapprove
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Complaint;
