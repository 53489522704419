import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// API endpoint
const API_URL = 'https://server.oracy.uk/api/admin/notice';

// Initial state
const initialState = {
  notices: [],
  isLoading: false,
  error: null,
};

// Async Thunks for handling API calls
export const fetchNotices = createAsyncThunk('notices/fetchNotices', async () => {
  const response = await axios.get(API_URL, { withCredentials: true });
  return response.data; 
});

export const addNotice = createAsyncThunk('notices/addNotice', async (noticeData) => {
  const response = await axios.post(API_URL, noticeData, { withCredentials: true });
  return response.data;
});

export const updateNotice = createAsyncThunk('notices/updateNotice', async ({ id, noticeData }) => {
  const response = await axios.put(`${API_URL}/${id}`, noticeData, { withCredentials: true });
  return response.data;
});

export const deleteNotice = createAsyncThunk('notices/deleteNotice', async (id) => {
  await axios.delete(`${API_URL}/${id}`, { withCredentials: true });
  return id; 
});

// Slice
const noticesSlice = createSlice({
  name: 'notices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchNotices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notices = action.payload;
      })
      .addCase(fetchNotices.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addNotice.fulfilled, (state, action) => {
        state.notices.push(action.payload);
      })
      .addCase(updateNotice.fulfilled, (state, action) => {
        const index = state.notices.findIndex(notice => notice.id === action.payload.id);
        if (index !== -1) {
          state.notices[index] = action.payload;
        }
      })
      .addCase(deleteNotice.fulfilled, (state, action) => {
        state.notices = state.notices.filter(notice => notice.id !== action.payload);
      });
  },
});

export default noticesSlice.reducer;
