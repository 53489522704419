import React from 'react';
import { Avatar, Button, Typography, Card, CardContent, Grid } from '@mui/material';
import { Edit } from '@mui/icons-material';

const TeacherProfile = () => {
  return (
    <div className="profile-container" style={{ padding: '20px' }}>
      <Card style={{ padding: '20px', borderRadius: '15px' }}>
        <Grid container spacing={3}>
          {/* Profile Header */}
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Avatar src="/path/to/profile-pic.jpg" style={{ width: '100px', height: '100px', margin: 'auto' }} />
            <Typography variant="h4">Teacher Name</Typography>
            <Typography variant="subtitle1">Role: Teacher</Typography>
            <Button variant="outlined" startIcon={<Edit />}>Edit Profile</Button>
          </Grid>

          {/* Teaching Progress */}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h6">Teaching Progress</Typography>
              <div style={{ height: '10px', backgroundColor: '#e0e0e0', borderRadius: '5px', marginBottom: '10px' }}>
                <div style={{ width: '80%', height: '100%', backgroundColor: '#3f51b5', borderRadius: '5px' }}></div>
              </div>
              <Typography variant="subtitle1">Courses Taught: 10</Typography>
            </CardContent>
          </Grid>

          {/* Personal Information */}
          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h6">Personal Information</Typography>
              <Typography>Name: Teacher Name</Typography>
              <Typography>Email: teacher@example.com</Typography>
              <Typography>Phone: +123456789</Typography>
              <Button variant="text" startIcon={<Edit />}>Edit</Button>
            </CardContent>
          </Grid>

          {/* Courses/Subjects */}
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h6">Courses/Subjects</Typography>
              {/* Render a list of courses/subjects taught */}
              <ul>
                <li>Mathematics</li>
                <li>Science</li>
                <li>History</li>
              </ul>
            </CardContent>
          </Grid>

          {/* Settings & Preferences */}
          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h6">Settings & Preferences</Typography>
              {/* Render settings options here */}
              <Typography variant="body2">Notification Preferences</Typography>
              {/* Add more settings options as needed */}
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default TeacherProfile;
