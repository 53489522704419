import React, { useState } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, CircularProgress, Snackbar, TablePagination, IconButton, 
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
    Button, TextField, Box 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFetchTeachersQuery, useDeleteTeacherMutation } from '../../redux/services/api'; // Import hooks from the API

const Teachers = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedTeacherId, setSelectedTeacherId] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    // Using Redux Toolkit query hook to fetch teachers
    const { data, isLoading, error } = useFetchTeachersQuery({
        page: page + 1,
        rowsPerPage,
        search: searchTerm,
    });
    
    // Using Redux Toolkit mutation hook to delete teacher
    const [deleteTeacher] = useDeleteTeacherMutation();

    const handleDeleteClick = (id) => {
        setSelectedTeacherId(id);
        setOpenDialog(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteTeacher(selectedTeacherId);
            setSuccessMessage('Teacher deleted successfully!');
        } catch (error) {
            console.error('Failed to delete teacher:', error);
        } finally {
            setOpenDialog(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Box sx={{ padding: 3 }}>
            <TextField
                variant="outlined"
                placeholder="Search for teachers"
                value={searchTerm}
                onChange={handleSearch}
                fullWidth
                sx={{ mb: 3 }}
            />

            <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableRow>
                                <TableCell><strong>ID</strong></TableCell>
                                <TableCell><strong>Name</strong></TableCell>
                                <TableCell><strong>Email</strong></TableCell>
                                <TableCell align="center"><strong>Action</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.teachers.map((teacher) => (
                                <TableRow key={teacher.id} hover>
                                    <TableCell>{teacher.id}</TableCell>
                                    <TableCell>{teacher.name}</TableCell>
                                    <TableCell>{teacher.email}</TableCell>
                                    <TableCell align="center">
                                        <IconButton 
                                            onClick={() => handleDeleteClick(teacher.id)} 
                                            color="error" 
                                            disabled={isLoading}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            
            <TablePagination
                component="div"
                count={data?.total || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ mt: 2 }}
            />
            
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage('')}
                message={successMessage}
            />
            
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this teacher? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary" disabled={isLoading}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Teachers;
