import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://server.oracy.uk/api/",
    credentials: "include",
  }),

  endpoints: (builder) => ({
    
    //assign courses
    fetchAssignedCourses: builder.query({
      query: (studentId) => `admin/students/${studentId}/courses`,
  }),
  // Assign courses to a student
  assignCourses: builder.mutation({
      query: ({ studentId, courses }) => ({
          url: `admin/students/${studentId}/assign-courses`,
          method: 'POST',
          body: { courses },
      }),
  }),

    // Existing endpoints
    getStats: builder.query({
      query: () => "admin/stats",
    }),
    getUsers: builder.query({
      query: ({ page, rowsPerPage, search }) => ({
        url: "admin/users",
        params: {
          page,
          limit: rowsPerPage,
          search,
        },
      }),
    }),
    // Update user role
    updateUserRole: builder.mutation({
      query: ({ id, newRole }) => ({
        url: `admin/users/${id}/role`,
        method: "PUT",
        body: { role: newRole },
      }),
    }),
    // Delete user
    deleteUser: builder.mutation({
      query: (id) => ({
        url: `admin/users/${id}`,
        method: "DELETE",
      }),
    }),
    // Fetch instructors
    fetchInstructors: builder.query({
      query: () => 'admin/instructors',
    }),

    // New course-related endpoints
    fetchCourses: builder.query({
      query: () => "admin/courses",
      providesTags: ["Courses"],
    }),
    fetchCourse: builder.query({
      query: (id) => `admin/courses/${id}`,
    }),
    addCourse: builder.mutation({
      query: (newCourse) => ({
        url: "admin/courses",
        method: "POST",
        body: newCourse,
      }),
      invalidatesTags: ["Courses"],
    }),
    updateCourse: builder.mutation({
      query: ({ id, updatedCourse }) => ({
        url: `admin/courses/${id}`,
        method: "PUT",
        body: updatedCourse,
      }),
      invalidatesTags: ["Courses"],
    }),
    deleteCourse: builder.mutation({
      query: (id) => ({
        url: `admin/courses/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Courses"],
    }),

    exportUserData: builder.query({
      query: (fileType) => ({
        url: `/admin/export?fileType=${fileType}`,
        method: 'GET',
        responseType: 'text',
        headers: {
          'Accept': fileType === 'csv' ? 'text/csv' : 'application/json',
        },
      }),
      transformResponse: (response) => response, 
    }),    
    
    //for students

    fetchStudents: builder.query({
        query: ({ page, rowsPerPage, search }) => ({
          url: "admin/students",
          params: {
            page,
            limit: rowsPerPage,
            search,
          },
        }),
        providesTags: ["Students"],
      }),
      deleteStudent: builder.mutation({
        query: (id) => ({
          url: `admin/students/${id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Students"],
      }),
      
      //for teachers
      fetchTeachers: builder.query({
        query: ({ page, rowsPerPage, search }) => ({
          url: 'admin/teachers',
          params: {
            page,
            limit: rowsPerPage,
            search,
          },
        }),
        providesTags: ['Teachers'],
      }),
  
      // Delete teacher
      deleteTeacher: builder.mutation({
        query: (id) => ({
          url: `admin/teachers/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Teachers'],
      }),

      //for student dashboard

      // Fetch student stats
fetchStudentStats: builder.query({
  query: (studentId) => `student/stats/${studentId}`,
  providesTags: ["StudentStats"],
}),

getStudentCourses: builder.query({
  query: (studentId) => `/student/courses/${studentId}`,
}),

getStudentTeacher: builder.query({
  query: (studentId) => `/student/teacher/${studentId}`,
}),

getStudentComplaints: builder.query({
  query: (studentId) => `/student/complaints?sId=${studentId}`,
}),
addStudentComplaint: builder.mutation({
  query: ({ title, description, studentId, userId }) => ({
    url: '/student/complaints',
    method: 'POST',
    body: { title, description, sId: studentId, userId: userId },
  }),
}),
deleteStudentComplaint: builder.mutation({
  query: (complaintId) => ({
    url: `/student/complaints/${complaintId}`,
    method: 'DELETE',
  }),
}),

//for teacher dashboard

getTeacherCourses: builder.query({
      query: (teacherId) => `/teacher/courses/${teacherId}`,
    }),

    getTeacherStudents: builder.query({
      query: (teacherId) => `/teacher/students/${teacherId}`,
    }),

    getTeacherComplaints: builder.query({
      query: (teacherId) => `/teacher/complaints?tId=${teacherId}`,
    }),

    addTeacherComplaint: builder.mutation({
      query: ({ title, description, tId, userId }) => ({
        url: '/teacher/complaints',
        method: 'POST',
        body: { title, description, tId, userId },
      }),
    }),

    deleteTeacherComplaint: builder.mutation({
      query: (complaintId) => ({
        url: `/teacher/complaints/${complaintId}`,
        method: 'DELETE',
      }),
    }),

    getTeacherStats: builder.query({
      query: (teacherId) => `/teacher/stats/${teacherId}`,
    }),

    //for profile fetch
    fetchProfile: builder.query({
      query: (userId) => `/user/profile/${userId}`, // Adjust endpoint as per your server
    }),

  }),
});

export const {
  // Existing hooks
  useAssignCoursesMutation,
  useFetchAssignedCoursesQuery,
  useGetStatsQuery,
  useGetUsersQuery,
  useUpdateUserRoleMutation,
  useDeleteUserMutation,
  useFetchInstructorsQuery,
  useLazyExportUserDataQuery,

  // New course-related hooks
  useFetchCoursesQuery,
  useFetchCourseQuery,
  useAddCourseMutation,
  useUpdateCourseMutation,
  useDeleteCourseMutation,

  useFetchStudentsQuery,
  useDeleteStudentMutation,

  useFetchTeachersQuery,
  useDeleteTeacherMutation,

  useFetchStudentStatsQuery,
  useGetStudentCoursesQuery,
  useGetStudentTeacherQuery,
  useAddStudentComplaintMutation,
  useDeleteStudentComplaintMutation,
  useGetStudentComplaintsQuery,

  useGetTeacherCoursesQuery,
  useGetTeacherStudentsQuery,
  useAddTeacherComplaintMutation,
  useDeleteTeacherComplaintMutation,
  useGetTeacherComplaintsQuery,
  useGetTeacherStatsQuery,
  useFetchProfileQuery,
} = api;
