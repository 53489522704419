import React, { useState } from 'react';
import { Button, Card, CardContent, Typography, Grid, Box, CircularProgress, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useGetTeacherComplaintsQuery, useAddTeacherComplaintMutation, useDeleteTeacherComplaintMutation } from '../../redux/services/api'; // Import RTK Query hooks

const TeacherComplaint = () => {
  const [newComplaintTitle, setNewComplaintTitle] = useState('');
  const [newComplaintDescription, setNewComplaintDescription] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State to control dialog visibility
  const teacherId = localStorage.getItem('teacherId'); // Assuming teacher ID is stored in local storage
  const userId = localStorage.getItem('userId');

  // Fetch complaints for the logged-in teacher
  const { data: complaints, error, isLoading } = useGetTeacherComplaintsQuery(teacherId);

  // Add complaint mutation
  const [addComplaint] = useAddTeacherComplaintMutation();

  // Delete complaint mutation
  const [deleteComplaint] = useDeleteTeacherComplaintMutation();

  const handleAddComplaint = async () => {
    if (newComplaintTitle.trim() === '' || newComplaintDescription.trim() === '') return;
    setSubmitting(true);
    try {
      await addComplaint({
        title: newComplaintTitle,
        description: newComplaintDescription,
        tId: teacherId,
        userId: userId,
      }).unwrap(); // Unwrap the result to catch errors
      setNewComplaintTitle(''); // Clear title input after submission
      setNewComplaintDescription(''); // Clear description input after submission
      setOpenDialog(false); // Close dialog after submission
    } catch (error) {
      console.error('Failed to add complaint:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteComplaint = async (complaintId) => {
    try {
      await deleteComplaint(complaintId).unwrap(); // Unwrap the result to catch errors
    } catch (error) {
      console.error('Failed to delete complaint:', error);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" color="red" sx={{ textAlign: 'center' }}>
          Failed to load complaints
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        My Complaints
      </Typography>

      {/* Button to open dialog for adding a new complaint */}
      <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)} sx={{ marginBottom: 3 }}>
        Add New Complaint
      </Button>

      {complaints?.teacherComplaints.length === 0 ? (
        <Typography>No complaints to display</Typography>
      ) : (
        <Grid container spacing={3}>
          {complaints.teacherComplaints.map((complaint) => (
            <Grid item xs={12} sm={6} md={4} key={complaint.id}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6">Complaint: {complaint.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {complaint.description}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Status:
                    {complaint.status === 'approved' && <Typography color="green">Approved</Typography>}
                    {complaint.status === 'disapproved' && <Typography color="red">Disapproved</Typography>}
                    {complaint.status === null && <Typography color="orange">On Hold</Typography>}
                  </Typography>
                </CardContent>
                <CardContent>
                  {complaint.status !== 'approved' && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteComplaint(complaint.id)}
                    >
                      Delete Complaint
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Dialog to add a new complaint */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Submit a New Complaint</DialogTitle>
        <DialogContent>
          <TextField
            label="Complaint Title"
            fullWidth
            value={newComplaintTitle}
            onChange={(e) => setNewComplaintTitle(e.target.value)}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Complaint Description"
            multiline
            fullWidth
            rows={4}
            value={newComplaintDescription}
            onChange={(e) => setNewComplaintDescription(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddComplaint} color="primary" disabled={submitting}>
            {submitting ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TeacherComplaint;
