// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import roleReducer from './slices/roleSlice';
import noticesReducer from './slices/noticesSlice';
import complaintReducer from './slices/complaintSlice';
import { api } from './services/api';

const store = configureStore({
  reducer: {
    role: roleReducer,
    notices: noticesReducer,
    complaints: complaintReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

export default store;
