import React, { useState } from 'react';
import { 
  Button, Card, CardContent, Typography, Grid, Box, CircularProgress, Dialog, DialogActions, 
  DialogContent, DialogTitle, TextField 
} from '@mui/material';
import { useGetStudentComplaintsQuery, useAddStudentComplaintMutation, useDeleteStudentComplaintMutation } from '../../redux/services/api';

const StudentComplaint = () => {
  const studentId = localStorage.getItem('studentId'); // Assuming student ID is stored in local storage
  const userId = localStorage.getItem('userId');
  const [newComplaintTitle, setNewComplaintTitle] = useState('');
  const [newComplaintDescription, setNewComplaintDescription] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // RTK Query hooks for complaints
  const { data: complaints, error, isLoading, refetch } = useGetStudentComplaintsQuery(studentId);
  const [addComplaint, { isLoading: isSubmitting }] = useAddStudentComplaintMutation();
  const [deleteComplaint] = useDeleteStudentComplaintMutation();

  const handleAddComplaint = async () => {
    if (newComplaintTitle.trim() === '' || newComplaintDescription.trim() === '') return;
    try {
      await addComplaint({ title: newComplaintTitle, description: newComplaintDescription, studentId, userId }).unwrap();
      setNewComplaintTitle('');
      setNewComplaintDescription('');
      setIsDialogOpen(false); // Close dialog after submission
      refetch(); // Refetch complaints after adding
    } catch (error) {
      console.error('Failed to add complaint:', error);
    }
  };

  const handleDeleteComplaint = async (complaintId) => {
    try {
      await deleteComplaint(complaintId).unwrap();
      refetch(); // Refetch complaints after deletion
    } catch (error) {
      console.error('Failed to delete complaint:', error);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        My Complaints
      </Typography>

      {/* Button to open dialog */}
      <Button 
        variant="contained" 
        color="primary" 
        sx={{ marginBottom: 3 }} 
        onClick={() => setIsDialogOpen(true)}
      >
        Add Complaint
      </Button>

      {/* Dialog for adding a new complaint */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Submit a New Complaint</DialogTitle>
        <DialogContent>
          <TextField
            label="Complaint Title"
            fullWidth
            value={newComplaintTitle}
            onChange={(e) => setNewComplaintTitle(e.target.value)}
            variant="outlined"
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Complaint Description"
            multiline
            fullWidth
            rows={4}
            value={newComplaintDescription}
            onChange={(e) => setNewComplaintDescription(e.target.value)}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddComplaint} 
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Add Complaint'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Complaints List */}
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Typography variant="body1" color="red">
            Error fetching complaints
          </Typography>
        </Box>
      ) : complaints?.studentComplaints.length === 0 ? (
        <Typography>No complaints to display</Typography>
      ) : (
        <Grid container spacing={3}>
          {complaints.studentComplaints.map((complaint) => (
            <Grid item xs={12} sm={6} md={4} key={complaint.id}>
              <Card variant="outlined" sx={{ height: '100%' }}>
                <CardContent>
                  <Typography variant="h6">Complaint: {complaint.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {complaint.description}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Status: 
                    {complaint.status === 'approved' && (
                      <Typography color="green">Approved</Typography>
                    )}
                    {complaint.status === 'disapproved' && (
                      <Typography color="red">Disapproved</Typography>
                    )}
                    {complaint.status === null && (
                      <Typography color="orange">On Hold</Typography>
                    )}
                  </Typography>
                </CardContent>
                <CardContent>
                  {complaint.status !== 'approved' && (
                    <Button 
                      variant="outlined" 
                      color="error" 
                      onClick={() => handleDeleteComplaint(complaint.id)}
                    >
                      Delete Complaint
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default StudentComplaint;
