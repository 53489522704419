import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CourseTable from './CourseTable';
import CourseForm from './CourseForm';
import { useFetchCoursesQuery, useAddCourseMutation, useUpdateCourseMutation, useDeleteCourseMutation } from '../../redux/services/api';

const Course = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { data: courses, error, isLoading } = useFetchCoursesQuery();
  const [addCourse] = useAddCourseMutation();
  const [updateCourse] = useUpdateCourseMutation();
  const [deleteCourse] = useDeleteCourseMutation();

  const handleAddCourse = () => {
    setSelectedCourse({}); 
    setIsEditing(true);
    setIsDialogOpen(true);
  };

  const handleEditCourse = (course) => {
    setSelectedCourse(course);
    setIsEditing(true);
    setIsDialogOpen(true);
  };

  const handleViewCourse = (course) => {
    setSelectedCourse(course);
    setIsEditing(false);
    setIsDialogOpen(true);
  };

  const handleDeleteCourse = async (id) => {
    try {
      await deleteCourse(id);
    } catch (error) {
      console.error('Failed to delete course:', error);
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedCourse(null);
  };

  const handleSave = async (updatedCourse) => {
    try {
      if (selectedCourse?.id) {
        await updateCourse({ id: selectedCourse.id, updatedCourse });
      } else {
        await addCourse(updatedCourse);
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Failed to save course:', error);
    }
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading courses</div>;

  return (
    <Box sx={{ p: 3 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddCourse}
      >
        Add Course
      </Button>
      <CourseTable
        courses={courses}
        onEditCourse={handleEditCourse}
        onViewCourse={handleViewCourse}
        onDeleteCourse={handleDeleteCourse}
      />
      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{isEditing ? (selectedCourse?.id ? 'Edit Course' : 'Add New Course') : 'View Course'}</DialogTitle>
        <DialogContent>
          <CourseForm
            course={selectedCourse}
            onSave={handleSave}
            onClose={handleCloseDialog}
            isEditing={isEditing}
          />
        </DialogContent>
        {isEditing && (
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Cancel
            </Button>
            <Button type="submit" form="courseForm" color="primary">
              Save
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};

export default Course;
