import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { setRole, setId, setAdminId, setStudentId, setTeacherId } from '../redux/slices/roleSlice';
import { Typography, Box, CircularProgress, Button } from '@mui/material';

const DefaultPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`https://server.oracy.uk/auth/current_user`, {
          withCredentials: true, 
        });

        const id = response.data.id; 
        const role = response.data.role;

        // Dispatch the role and id to the Redux store
        dispatch(setId(id));
        dispatch(setRole(role));

        // Redirect based on role
        if (role === 1) {
          const adminId = response.data.adminId;
          dispatch(setAdminId(adminId));
          navigate('/admin/dashboard');
        } else if (role === 2) {
          const teacherId = response.data.teacherId;
          dispatch(setTeacherId(teacherId));
          navigate('/teacher/dashboard');
        } else if (role === 3) {
          const studentId = response.data.studentId;
          dispatch(setStudentId(studentId)); 
          navigate('/student/dashboard');
        } else {
          setLoading(false);
        }
      } catch (error) {
        if(error.status == 401){
          window.location.href = 'https://server.oracy.uk/auth/google';
        }
        console.error('Error fetching user data:', error);
        setLoading(false); 
      }
    };

    fetchUserData();
  }, [dispatch, navigate]);

  if (loading ) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: '40px',
        textAlign: 'center',
        backgroundColor: '#f0f4f8', 
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        Thank You for Logging In
      </Typography>
      <Typography variant="body1" paragraph>
        We have received your login request. Your account is currently under review, and you will be granted access once an administrator assigns your role.
      </Typography>
      <Typography variant="body1" paragraph>
        Meanwhile, feel free to explore our website and check out the latest features and updates.
      </Typography>
      <Button
        variant="contained"
        href="https://oracy.uk/"
        sx={{
          marginTop: '20px',
          backgroundColor: '#007bff',
          color: '#fff',
          padding: '10px 20px',
          textTransform: 'none',
          fontSize: '16px',
          '&:hover': {
            backgroundColor: '#0056b3',
          },
        }}
      >
        Visit Our Website
      </Button>
    </Box>
  );
};

export default DefaultPage;
