import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress, Button } from '@mui/material';
import { useGetTeacherStudentsQuery } from '../../redux/services/api'; // Import RTK Query hook
import Avatar from '@mui/material/Avatar';

const MyStudents = () => {
  const teacherId = localStorage.getItem('teacherId'); // Assuming teacher ID is stored in local storage

  // RTK Query hook for fetching students
  const { data: students, error, isLoading, refetch } = useGetTeacherStudentsQuery(teacherId);

  if (isLoading) {
    return (
      <Box sx={{ padding: '20px', backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: '20px', backgroundColor: '#f5f5f5', minHeight: '100vh', textAlign: 'center' }}>
        <Typography variant="body1" color="error">Error fetching students. Please try again.</Typography>
        <Button variant="contained" color="primary" onClick={refetch} sx={{ marginTop: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#3f51b5', textAlign: 'center', fontWeight: 'bold' }}>
        👩‍🏫 Your Students
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        {students && students.TeacherStudents.length > 0 ? (
          students.TeacherStudents.map((student) => (
            <Grid item xs={12} sm={6} md={4} key={student.id}>
              <Card 
                sx={{ 
                  textAlign: 'center', 
                  boxShadow: 1, 
                  borderRadius: '12px', 
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 3,
                  }
                }}
              >
                <CardContent>
                  <Avatar alt={student.studentName} src={student.profilePicture} sx={{ width: 56, height: 56, margin: '0 auto' }} />
                  <Typography variant="h6" sx={{ color: '#3f51b5', marginTop: 2 }}>
                    {student.studentName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#616161' }}>
                    Email: {student.studentEmail}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#616161' }}>
                    Courses: {student.courses.map(course => course.courseName).join(', ')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" sx={{ textAlign: 'center' }}>No students available</Typography>
        )}
      </Grid>
    </Box>
  );
};

export default MyStudents;
