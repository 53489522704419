import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, Button, Modal, CircularProgress, CardMedia } from '@mui/material';
import { useGetTeacherCoursesQuery } from '../../redux/services/api'; // Import RTK Query hook

const TeacherCourses = () => {
  const teacherId = localStorage.getItem('teacherId'); // Assuming teacher ID is stored in local storage
  const [selectedCourse, setSelectedCourse] = useState(null);

  const { data: courses, error, isLoading } = useGetTeacherCoursesQuery(teacherId);

  const handleCardClick = (course) => setSelectedCourse(course);
  const handleClose = () => setSelectedCourse(null);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '60vh', textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: 2 }}>
          Loading courses, please wait...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
        <Typography variant="body1" sx={{ color: 'red', textAlign: 'center' }}>
          Unable to fetch courses. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#3f51b5', textAlign: 'center', fontWeight: 'bold' }}>
        📚 Your Courses
      </Typography>

      {courses && courses.length > 0 ? (
        <Grid container spacing={3} justifyContent="center">
          {courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={course.id}>
              <Card
                sx={{
                  textAlign: 'center',
                  boxShadow: 2,
                  borderRadius: '12px',
                  overflow: 'hidden',
                  transition: 'transform 0.2s ease-in-out',
                  height: '100%',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="150"
                  image={course.image ? `https://server.oracy.uk/uploads/${course.image}` : 'https://via.placeholder.com/345x150'}
                  alt={course.title}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent sx={{ padding: '16px' }}>
                  <Typography variant="h6" sx={{ color: '#3f51b5', marginBottom: 1 }}>
                    {course.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#616161', marginBottom: 2 }}>
                    {course.description ? course.description.substring(0, 60) + '...' : 'No description available'}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: course.status === 'active' ? 'green' : 'red',
                      fontWeight: 'bold',
                      marginBottom: 2,
                    }}
                  >
                    Status: {course.status.charAt(0).toUpperCase() + course.status.slice(1)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      padding: '8px 16px',
                      borderRadius: '8px',
                      fontSize: '0.875rem',
                    }}
                    onClick={() => handleCardClick(course)}
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
          <Typography variant="h6" color="textSecondary">
            No courses available
          </Typography>
          <Typography variant="body2" sx={{ color: '#9e9e9e', marginTop: 1 }}>
            Please check back later or contact your administrator.
          </Typography>
        </Box>
      )}

      {selectedCourse && (
        <Modal open={Boolean(selectedCourse)} onClose={handleClose}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              borderRadius: '12px',
              boxShadow: 24,
              padding: 3,
              maxWidth: '600px',
              margin: 'auto',
              marginTop: '10vh',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
              {selectedCourse.title}
            </Typography>
            <CardMedia
              component="img"
              height="200"
              image={selectedCourse.image ? `https://server.oracy.uk/uploads/${selectedCourse.image}` : 'https://via.placeholder.com/345x200'}
              alt={selectedCourse.title}
              sx={{
                objectFit: 'cover',
                borderRadius: '12px',
                marginBottom: 2,
              }}
            />
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              <strong>Description:</strong> {selectedCourse.description || 'No description available'}
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              <strong>Status:</strong>{' '}
              <span style={{ color: selectedCourse.status === 'active' ? 'green' : 'red', fontWeight: 'bold' }}>
                {selectedCourse.status.charAt(0).toUpperCase() + selectedCourse.status.slice(1)}
              </span>
            </Typography>
            <Button onClick={handleClose} variant="contained" color="primary">
              Close
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default TeacherCourses;
