import React, { useState } from 'react';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  Button,
  Modal,
  CircularProgress,
  CardMedia,
  Skeleton,
} from '@mui/material';
import { useGetStudentCoursesQuery } from '../../redux/services/api';

const StudentCourses = () => {
  const studentId = localStorage.getItem('studentId'); // Assuming student ID is stored in local storage
  const { data: courses, error, isLoading, refetch } = useGetStudentCoursesQuery(studentId);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const handleCardClick = (course) => {
    setSelectedCourse(course);
  };

  const handleClose = () => {
    setSelectedCourse(null);
  };

  if (isLoading) {
    return (
      <Grid container spacing={2} justifyContent="center">
        {Array.from(new Array(6)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Skeleton variant="rectangular" height={200} />
            <Skeleton variant="text" sx={{ mt: 1 }} />
            <Skeleton variant="text" width="60%" />
          </Grid>
        ))}
      </Grid>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6" color="error">Failed to load courses. Please try again.</Typography>
        <Button variant="contained" color="primary" onClick={refetch} sx={{ mt: 2 }}>
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ color: '#3f51b5', textAlign: 'center', fontWeight: 'bold' }}
      >
        📚 Your Enrolled Courses
      </Typography>

      {courses?.length === 0 ? (
        <Typography variant="h6" color="textSecondary" textAlign="center">
          You are not enrolled in any courses yet.
        </Typography>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          {courses.map((course) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={course.id}>
              <Card
                sx={{
                  textAlign: 'center',
                  boxShadow: 3,
                  borderRadius: '8px',
                  overflow: 'hidden',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: 5,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  height="120"
                  image={course.image ? `https://server.oracy.uk/uploads/${course.image}` : 'https://via.placeholder.com/345x140'}
                  alt={course.title}
                  sx={{ objectFit: 'cover' }}
                />
                <CardContent>
                  <Typography variant="h6" color="primary" gutterBottom>
                    {course.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>
                    {course.description ? course.description.substring(0, 60) + '...' : 'No description available'}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: course.status === 'ongoing' ? 'green' : 'gray',
                      fontWeight: 'bold',
                    }}
                  >
                    Status: {course.status.charAt(0).toUpperCase() + course.status.slice(1)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleCardClick(course)}
                    sx={{ mt: 2 }}
                  >
                    View Details
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {selectedCourse && (
        <Modal open={Boolean(selectedCourse)} onClose={handleClose}>
          <Box
            sx={{
              bgcolor: 'background.paper',
              borderRadius: '12px',
              boxShadow: 24,
              padding: 4,
              maxWidth: '500px',
              margin: 'auto',
              marginTop: '10vh',
              textAlign: 'center',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 2 }}>
              {selectedCourse.title}
            </Typography>
            <CardMedia
              component="img"
              height="200"
              image={selectedCourse.image ? `https://server.oracy.uk/uploads/${selectedCourse.image}` : 'https://via.placeholder.com/345x140'}
              alt={selectedCourse.title}
              sx={{
                objectFit: 'cover',
                borderRadius: '8px',
              }}
            />
            <Typography variant="body1" sx={{ mt: 2 }}>
              <strong>Description:</strong> {selectedCourse.description || 'No description available'}
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }}>
              <strong>Status:</strong>{' '}
              <span
                style={{
                  color: selectedCourse.status === 'ongoing' ? 'green' : 'gray',
                  fontWeight: 'bold',
                }}
              >
                {selectedCourse.status.charAt(0).toUpperCase() + selectedCourse.status.slice(1)}
              </span>
            </Typography>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              sx={{ mt: 3 }}
            >
              Close
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default StudentCourses;
