import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element, allowedRoles }) => {
  const role = useSelector((state) => state.role.role);

  if (allowedRoles.includes(role)) {
    return element; 
  } else {
    return <Navigate to="/" />;   
  }
};

export default ProtectedRoute;
