import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const NoticeDialog = ({ open, onClose, onSave, notice, isEditing }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  // If the dialog is open and we're editing an existing notice, populate the fields with the notice data
  useEffect(() => {
    if (isEditing && notice) {
      setTitle(notice.title);
      setDescription(notice.description);
    } else {
      setTitle('');
      setDescription('');
    }
  }, [isEditing, notice, open]);

  const handleSave = () => {
    if (!title || !description) {
      // Prevent saving if fields are empty
      alert('Please fill out both fields!');
      return;
    }
    // Prepare the notice data
    const noticeData = {
      title,
      description,
    };
    onSave(noticeData); // Call onSave (which will either add or update a notice)
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{isEditing ? 'Edit Notice' : 'Add New Notice'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Title"
          fullWidth
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          margin="dense"
          label="Description"
          fullWidth
          variant="outlined"
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NoticeDialog;
