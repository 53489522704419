import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useGetStudentTeacherQuery } from '../../redux/services/api';

const MyTeacher = () => {
  const studentId = localStorage.getItem('studentId'); // Assuming student ID is stored in local storage
  const { data: teachers, error, isLoading } = useGetStudentTeacherQuery(studentId); // Use the RTK Query hook

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', backgroundColor: '#f5f5f5' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: '20px', backgroundColor: '#f5f5f5', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography variant="h6" sx={{ color: 'red', textAlign: 'center' }}>
          Unable to fetch teacher information. Please try again later.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#3f51b5', textAlign: 'center', fontWeight: 'bold' }}>
        👨‍🏫 Your Teacher
      </Typography>

      {teachers && teachers.StudentTeachers.length > 0 ? (
        <Grid container spacing={3} justifyContent="center">
          {teachers.StudentTeachers.map((teacher) => (
            <Grid item xs={12} sm={6} md={4} key={teacher.id}>
              <Card
                sx={{
                  textAlign: 'center',
                  boxShadow: 2,
                  borderRadius: '12px',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': { transform: 'scale(1.03)' },
                }}
              >
                <CardContent>
                  <Avatar
                    alt={teacher.teacherName}
                    src={teacher.profilePicture || 'https://via.placeholder.com/150'}
                    sx={{ width: 80, height: 80, margin: '0 auto', boxShadow: 2 }}
                  />
                  <Typography variant="h5" sx={{ color: '#3f51b5', marginTop: 2 }}>
                    {teacher.teacherName}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#616161', marginBottom: 1 }}>
                    <strong>Email:</strong> {teacher.teacherEmail}
                  </Typography>
                  <Typography variant="body2" sx={{ color: '#616161', marginBottom: 1 }}>
                    <strong>Courses:</strong> {teacher.courses.length > 0 ? teacher.courses.map(course => course.courseName).join(', ') : 'N/A'}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
          <Typography variant="h6" sx={{ color: '#616161' }}>
            No teacher has been assigned to you yet.
          </Typography>
          <Typography variant="body2" sx={{ color: '#9e9e9e', marginTop: 1 }}>
            Please check back later or contact your administrator for more information.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default MyTeacher;
