import React from 'react';
import { Typography, Box, Grid, Card, CardContent, Button, CircularProgress, Paper } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { useGetStatsQuery } from '../../redux/services/api';

const AdminDashboard = () => {
  const { data, error, isLoading } = useGetStatsQuery();  

  const chartData = {
    labels: ['March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Number of Users',
        data: [3, 5, 2, 3],
        borderColor: '#42A5F5',
        backgroundColor: 'rgba(66, 165, 245, 0.2)',
      },
    ],
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Welcome to the Admin Dashboard
      </Typography>
      <Typography variant="body1" paragraph>
        This is the main dashboard where you can see an overview of the platform’s activity. Use the sidebar to manage students, teachers, courses, and more.
      </Typography>

      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">Error fetching data: {error.message}</Typography>
      ) : (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Total Students</Typography>
                  <Typography variant="h4">{data.totalStudents}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Total Teachers</Typography>
                  <Typography variant="h4">{data.totalTeachers}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Total Courses</Typography>
                  <Typography variant="h4">{data.totalCourses}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Quick Actions</Typography>
                  <Button variant="contained" color="primary" fullWidth component={NavLink} to="/admin/courses">
                    Manage Courses
                  </Button>
                  <Button variant="contained" color="secondary" fullWidth sx={{ mt: 1 }} component={NavLink} to="/admin/users">
                    Manage Users
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Typography variant="h6">Activity Overview</Typography>
            <Paper sx={{ padding: '16px', marginTop: '16px' }}>
              <Line data={chartData} />
            </Paper>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AdminDashboard;
