import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { Box, TextField, Button, Typography, IconButton, InputAdornment } from '@mui/material';
import Picker from '@emoji-mart/react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';


const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

export const Discussions = () => {
    // Refs
    const messageListRef = useRef(null);
    const socket = useRef(null);

    // State
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    // Redux selectors
    const userId = useSelector((state) => state.role.id);
    const userRole = useSelector((state) => state.role.role);

    // Socket initialization
    useEffect(() => {
        socket.current = io('https://server.oracy.uk', {
            auth: { user: { userId, userRole } },
            transports: ['websocket', 'polling'],
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            timeout: 10000,
        });

        const socketHandlers = {
            connect: () => {
                console.log('Connected to server');
                setError(null);
                loadMessages(0); // Load initial messages
            },
            disconnect: (reason) => {
                console.log('Disconnected:', reason);
                setError('Connection lost. Attempting to reconnect...');
            },
            connect_error: (err) => {
                console.error('Connection error:', err);
                setError('Failed to connect to server');
            },
            initialMessages: (data) => {
                setMessages(data);
                setLoading(false);
                scrollToBottom();
            },
            message: (data) => {
                
                setMessages(prev => {
                    const messageExists = prev.some(msg => 
                        (msg.id === data.id) || 
                        (msg.tempId && msg.tempId === data.tempId)
                    );
    
                    if (messageExists) {
                        // Update existing message with server data
                        return prev.map(msg => 
                            (msg.tempId === data.tempId || msg.id === data.id)
                                ? { ...msg, ...data, status: 'sent' }
                                : msg
                        );
                    } else {
                        return [...prev, data];
                    }
                });
                scrollToBottom();
            },
            messageAck: ({ id }) => {
                setMessages(prev => 
                    prev.map(msg => 
                        msg.tempId === id ? { ...msg, status: 'sent' } : msg
                    )
                );
            },
            messageError: (error) => {
                setError(`Failed to send message: ${error}`);
                setMessages(prev => 
                    prev.map(msg => 
                        msg.status === 'sending' ? { ...msg, status: 'failed' } : msg
                    )
                );
            },
            moreMessages: ({ messages: newMessages, hasMore: more }) => {
              setMessages(prev => {
                  const existingMessageIds = new Set(prev.map(msg => msg.id));
                  const uniqueMessages = newMessages.filter(msg => !existingMessageIds.has(msg.id));
                  return [...uniqueMessages, ...prev];
              });
              setHasMore(more);
              setLoading(false);
          }
        };

        // Register all socket handlers
        Object.entries(socketHandlers).forEach(([event, handler]) => {
            socket.current.on(event, handler);
        });

        return () => {
            Object.keys(socketHandlers).forEach(event => {
                socket.current.off(event);
            });
            socket.current.disconnect();
        };
    }, [userId, userRole]);

    // Scroll handlers
    const scrollToBottom = () => {
        if (messageListRef.current) {
            requestAnimationFrame(() => {
                messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
            });
        }
    };

    const handleScroll = useCallback(
        debounce(() => {
            const messageList = messageListRef.current;
            if (messageList && !loading && hasMore) {
                const { scrollTop } = messageList;
                if (scrollTop === 0) {
                    loadMessages(page);
                    setPage(prev => prev + 1);
                }
            }
        }, 150),
        [loading, hasMore, page]
    );

    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            messageList.addEventListener('scroll', handleScroll);
            return () => messageList.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    // Message loading
    const loadMessages = async (p = 0) => {
        setLoading(true);
        socket.current.emit('loadMoreMessages', { page: p });
    };

    // File handling
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            // Check file size
            if (selectedFile.size > 5 * 1024 * 1024) {
                setError('File size exceeds 5MB limit');
                return;
            }
            
            // Set file and create preview
            setFile(selectedFile);
            setFilePreview(URL.createObjectURL(selectedFile));
        }
    };

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    // Message sending
    const handleSendMessage = async () => {
        if (!message.trim() && !file) return;
    
        try {
            const tempId = Date.now().toString() + Math.random().toString(36).substring(2, 10);
    
            // If there's a file, prepare it
            let fileData = null;
            if (file) {
                fileData = await new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve({
                        data: reader.result,  // Base64 encoded file
                        name: file.name,
                        type: file.type
                    });
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            }
    
            // Create preview message
            const newMessage = {
                content: message,
                user_id: userId,
                sender: 'You',
                file: fileData ? {
                    name: file.name,
                    type: file.type
                } : null,
                timestamp: new Date().toISOString(),
                tempId,
                status: 'sending'
            };
    
            // Add to local state for immediate display
            setMessages(prev => [...prev, newMessage]);
            
            // Send to server
            socket.current.emit('message', {
                content: message,
                file: fileData,  // Will be null for text-only messages
                tempId
            });
    
            // Clear inputs
            setMessage('');
            setFile(null);
            setFilePreview(null);
            scrollToBottom();
    
        } catch (err) {
            console.error('Error sending message:', err);
            setError('Failed to send message');
        }
    };

    // Emoji handling
    const handleAddEmoji = (emoji) => {
        setMessage((prev) => prev + emoji.native);
        setShowEmojiPicker(false);
    };

    // Message filtering
    const filteredMessages = messages.filter((msg) =>
        msg.content?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (    
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '90vh',
            width: '100%',
            p: 2,
            bgcolor: '#f5f5f5',
        }}>
            {/* Search Bar */}
            <Box sx={{ mb: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    placeholder="Search messages..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>

            {/* Message Display */}
            <Box
                ref={messageListRef}
                sx={{
                    flex: 1,
                    overflowY: 'auto',
                    mb: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                {error && (
                    <Typography color="error" align="center" sx={{ py: 2 }}>
                        {error}
                    </Typography>
                )}
                {loading && (
                    <Typography align="center" sx={{ py: 2 }}>
                        Loading messages...
                    </Typography>
                )}
                {hasMore && !loading && (
                    <Button
                        onClick={() => loadMessages(page)}
                        sx={{ alignSelf: 'center', mb: 2 }}
                    >
                        Load More
                    </Button>
                )}
                {filteredMessages.map((msg, index) => (
                    <MessageItem
                        key={msg.id || msg.tempId || index}
                        message={msg}
                        isCurrentUser={msg.user_id == userId}
                    />
                ))}
            </Box>

            {/* Message Input */}
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', position: 'relative' }}>
                <IconButton onClick={() => setShowEmojiPicker((prev) => !prev)}>
                    <EmojiEmotionsIcon />
                </IconButton>
                {showEmojiPicker && (
                    <Box sx={{ position: 'absolute', bottom: '70px', zIndex: 10 }}>
                        <Picker onEmojiSelect={handleAddEmoji} />
                    </Box>
                )}
                <IconButton component="label">
                    <AttachFileIcon />
                    <input
                        type="file"
                        hidden
                        onChange={handleFileChange}
                        accept="image/*,.pdf,.doc,.docx"
                    />
                </IconButton>
                {filePreview && (
                    <Box sx={{
                        maxWidth: '150px',
                        maxHeight: '150px',
                        mb: 1,
                        borderRadius: 2,
                        overflow: 'hidden',
                    }}>
                        <img
                            src={filePreview}
                            alt="Preview"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    </Box>
                )}
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Type your message..."
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            handleSendMessage();
                        }
                    }}
                />
                <Button
                    variant="contained"
                    onClick={handleSendMessage}
                    disabled={(!message.trim() && !file) || loading}
                >
                    Send
                </Button>
            </Box>
        </Box>
    );
};

const MessageItem = React.memo(({ message, isCurrentUser }) => {
    const getStatusIcon = (status) => {
        switch (status) {
            case 'sending':
                return '⏳';
            case 'sent':
                return '✓✓';
            case 'failed':
                return '❌';
            default:
                return '';
        }
    };

    // Function to generate a consistent color based on sender name
    const getSenderColor = (sender) => {
        const colors = ['#FF6B6B', '#4ECDC4', '#45B7D1', '#96CEB4', '#FFEEAD', '#D4A5A5', '#9B59B6', '#3498DB'];
        const index = sender.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
        return colors[index % colors.length];
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: isCurrentUser ? 'flex-end' : 'flex-start',
                px: 2,
                mb: 1.5,
            }}
        >
            <Box
                sx={{
                    maxWidth: '75%',
                    p: 2,
                    bgcolor: isCurrentUser ? 'blue' : '#f5f5f5',
                    color: isCurrentUser ? '#fff' : '#000',
                    borderRadius: isCurrentUser ? '16px 16px 0 16px' : '16px 16px 16px 0',
                    boxShadow: 1,
                    position: 'relative',
                }}
            >
                {/* Sender Name */}
                {!isCurrentUser && (
                    <Typography
                        variant="caption"
                        sx={{
                            display: 'block',
                            color: getSenderColor(message.sender || 'Unknown'),
                            fontWeight: 600,
                            mb: 0.5,
                        }}
                    >
                        {message.sender}
                    </Typography>
                )}
                {message.file && (
                    <Box
                        sx={{
                            mb: 1,
                            p: 1,
                            bgcolor: 'rgba(0, 0, 0, 0.08)',
                            borderRadius: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        {/* Preview for images */}
                        {message.file_type?.startsWith('image/') && message.file_url && (
                            <Box
                                sx={{
                                    width: '100%',
                                    maxHeight: '200px',
                                    overflow: 'hidden',
                                    borderRadius: 1,
                                    mb: 1,
                                }}
                            >
                                <img
                                    src={`https://server.oracy.uk${message.file_url}`}
                                    alt="attachment"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'contain',
                                    }}
                                    onClick={() => window.open(`https://server.oracy.uk${message.file_url}`, '_blank')}
                                />
                            </Box>
                        )}
    
                        {/* File info and download button */}
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontWeight: 500,
                                        color: isCurrentUser ? '#fff' : '#333',
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    📎 {message.file.name}
                                </Typography>
                                {message.file_size && (
                                    <Typography variant="caption" sx={{ color: isCurrentUser ? '#ddd' : '#666' }}>
                                        ({(message.file_size / 1024).toFixed(1)} KB)
                                    </Typography>
                                )}
                            </Box>
                            {message.file_url && (
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = `https://server.oracy.uk${message.file_url}`;
                                        link.download = message.file.name;
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}
                                    sx={{
                                        color: isCurrentUser ? '#fff' : '#666',
                                        '&:hover': {
                                            bgcolor: isCurrentUser ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
                                        },
                                    }}
                                >
                                    <DownloadIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>
                    </Box>
                )}
    
                {/* Message Content */}
                <Typography
                    variant="body1"
                    sx={{
                        wordBreak: 'break-word',
                        lineHeight: 1.6,
                    }}
                >
                    {message.content}
                </Typography>
                <Box
                    sx={{
                        mt: 1,
                        display: 'flex',
                        justifyContent: isCurrentUser ? 'flex-end' : 'space-between',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    {!isCurrentUser && (
                        <Typography
                            variant="caption"
                            sx={{
                                color: '#888',
                                fontSize: '0.75rem',
                            }}
                        >
                            {new Date(message.timestamp || message.createdAt).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                            })}
                        </Typography>
                    )}
                    {isCurrentUser && (
                        <>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#ddd',
                                    fontSize: '0.75rem',
                                }}
                            >
                                {new Date(message.timestamp || message.createdAt).toLocaleTimeString([], {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true,
                                })}
                            </Typography>
                            <Typography
                                variant="caption"
                                sx={{
                                    color: '#ddd',
                                    fontSize: '0.75rem',
                                }}
                            >
                                {getStatusIcon(message.status)}
                            </Typography>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
});