import React, { useState } from 'react';
import { Drawer, List, ListItemIcon, ListItemText, Box, Divider, ListItemButton } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { Dashboard as DashboardIcon, People as PeopleIcon, Settings as SettingsIcon, Logout as LogoutIcon, CalendarMonthSharp as CalendarIcon, School as SchoolIcon, Group as GroupIcon, NotificationsActive, Report, GroupAdd as Discussion } from '@mui/icons-material';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { resetUser } from '../redux/slices/roleSlice';
import ConfirmDialog from './ConfirmDialog';

const Sidebar = ({ drawerWidth, mobileOpen, onDrawerToggle, userRole }) => {
  const dispatch = useDispatch();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false); // State to manage the dialog visibility

  const handleLogout = async () => {
    try {
      const response = await axios.get('https://server.oracy.uk/auth/logout', { withCredentials: true });
      if (response.status === 200) {
        dispatch(resetUser());
        window.location.href = 'https://oracy.uk/';
      } else {
        alert('Error logging out');
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  // Define drawer items based on user role
  const drawerItemsMap = {
    1: [
      { to: '/admin/users', icon: <PeopleIcon color="primary" />, text: 'Users' },
      { to: '/admin/students', icon: <SchoolIcon color="success" />, text: 'Students' },
      { to: '/admin/teachers', icon: <GroupIcon color="info" />, text: 'Teachers' },
      { to: '/admin/courses', icon: <DashboardIcon color="secondary" />, text: 'Courses' },
      { to: '/admin/notice', icon: <NotificationsActive color="action" />, text: 'Notice' },
      { to: '/admin/complaints', icon: <Report color="info" />, text: 'Complaints' },
      { to: '/admin/setting', icon: <SettingsIcon color="warning" />, text: 'Settings' },
      // { to: '/admin/calendar', icon: <CalendarIcon color="action" />, text: 'Calendar' },
      { to: '/admin/discussion', icon: <Discussion color= "info" />, text: 'Discussions'}
    ],
    2: [
      { to: '/teacher/students', icon: <PeopleIcon color="primary" />, text: 'My Students' },
      { to: '/teacher/courses', icon: <DashboardIcon color="secondary" />, text: 'My Courses' },
      { to: '/teacher/notice', icon: <NotificationsActive color="action" />, text: 'Notice' },
      { to: '/teacher/complaints', icon: <Report color="info" />, text: 'My Complaints' },
      // { to: '/teacher/calendar', icon: <CalendarIcon color="action" />, text: 'Calendar' }
    ],
    3: [
      { to: '/student/teachers', icon: <PeopleIcon color="primary" />, text: 'My Teachers' },
      { to: '/student/courses', icon: <DashboardIcon color="secondary" />, text: 'My Courses' },
      { to: '/student/notice', icon: <NotificationsActive color="action" />, text: 'Notice' },
      { to: '/student/complaints', icon: <Report color="info" />, text: 'My Complaints' },
      // { to: '/student/calendar', icon: <CalendarIcon color="action" />, text: 'Calendar' }
    ]
  };

  const drawerItems = drawerItemsMap[userRole] || [];

  const handleItemClick = () => {
    if (mobileOpen) {
      onDrawerToggle(); // Close the drawer on mobile view
    }
  };

  const drawer = (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ textAlign: 'center', padding: '16px' }} component={NavLink} to="/">
        <img src="/Logodark.svg" alt="Logo" style={{ width: '80%', maxWidth: '150px', height: 'auto' }} />
      </Box>
      <List sx={{ flexGrow: 1 }}>
        {drawerItems.map(({ to, icon, text }) => (
          <ListItemButton
            component={NavLink}
            to={to}
            key={text}
            style={({ isActive }) => ({
              backgroundColor: isActive ? '#b3e5fc' : '#f1f8e9',
              borderRadius: '8px',
              margin: '4px 0',
              transition: 'background-color 0.3s',
              color: isActive ? '#0d47a1' : 'inherit'
            })}
            onClick={handleItemClick}
          >
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={text} />
          </ListItemButton>
        ))}
      </List>
      <Divider />
      <List>
        <ListItemButton
          onClick={() => setConfirmDialogOpen(true)} // Open the confirmation dialog
          style={{ marginTop: 'auto', backgroundColor: '#f1f8e9', borderRadius: '8px', margin: '4px 0' }}
        >
          <ListItemIcon>
            <LogoutIcon color="error" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <nav>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
        }}
        open
      >
        {drawer}
      </Drawer>

      {/* Confirm Logout Dialog */}
      <ConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleLogout}
        title="Confirm Logout"
        message="Are you sure you want to log out?"
      />
    </nav>
  );
};

export default Sidebar;
