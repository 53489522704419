import React, { useState, useEffect } from 'react';
import { useFetchInstructorsQuery } from '../../redux/services/api'; 
import { TextField, Button, Typography, Grid, MenuItem, FormControl, InputLabel, Select, FormHelperText } from '@mui/material';

const CourseForm = ({ course, onSave, isEditing }) => {
    const [title, setTitle] = useState(course?.title || '');
    const [description, setDescription] = useState(course?.description || '');
    const [instructor, setInstructor] = useState(course?.instructorId || '');
    const [duration, setDuration] = useState(course?.duration || '');
    const [status, setStatus] = useState(course?.status || '');
    const [startDate, setStartDate] = useState(course?.startDate ? new Date(course.startDate).toISOString().split('T')[0] : '');
    const [endDate, setEndDate] = useState(course?.endDate ? new Date(course.endDate).toISOString().split('T')[0] : '');
    const [courseImage, setCourseImage] = useState(null);  // For storing the selected image file
    const [imagePreview, setImagePreview] = useState(course?.imageUrl || '');  // Preview the image URL
    const [errors, setErrors] = useState({});

    // Fetch instructors using RTK Query
    const { data: instructors = [], isLoading, isError } = useFetchInstructorsQuery();

    useEffect(() => {
        if (course) {
            setTitle(course.title);
            setDescription(course.description);
            setInstructor(course.instructorId);
            setDuration(course.duration);
            setStatus(course.status);
            setStartDate(course.startDate ? new Date(course.startDate).toISOString().split('T')[0] : '');
            setEndDate(course.endDate ? new Date(course.endDate).toISOString().split('T')[0] : '');
            setImagePreview(course.imageUrl || ''); // Set existing image URL if editing
        }
    }, [course]);

    const validate = () => {
        const newErrors = {};
        if (!title) newErrors.title = 'Title is required';
        if (!description) newErrors.description = 'Description is required';
        if (!instructor) newErrors.instructor = 'Instructor ID is required';
        if (!duration || isNaN(duration) || duration <= 0) newErrors.duration = 'Duration must be a positive number';
        if (!startDate) newErrors.startDate = 'Start Date is required';
        if (!endDate) newErrors.endDate = 'End Date is required';
        if (new Date(startDate) > new Date(endDate)) newErrors.dateRange = 'End Date must be after Start Date';
        if (!status) newErrors.status = 'Status is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCourseImage(file);
            setImagePreview(URL.createObjectURL(file));  // Preview the image in the form
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validate()) return;

        const updatedCourse = {
            title,
            description,
            instructorId: instructor,
            duration,
            startDate,
            endDate,
            status,
            image: courseImage,  // Include image in the data
        };

        onSave(updatedCourse);
    };

    if (isLoading) return <Typography>Loading Instructors...</Typography>;
    if (isError) return <Typography>Error fetching instructors!</Typography>;

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h6" style={{ marginBottom: '20px' }}>
                {isEditing ? 'Course Details' : 'View Course'}
            </Typography>
            <form id="courseForm" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                            disabled={!isEditing}
                            error={!!errors.title}
                            helperText={errors.title}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                            multiline
                            rows={4}
                            required
                            disabled={!isEditing}
                            error={!!errors.description}
                            helperText={errors.description}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel>Instructor</InputLabel>
                            <Select
                                value={instructor}
                                onChange={(e) => setInstructor(e.target.value)}  // Set the selected instructor's ID
                                disabled={!isEditing}
                            >
                                {instructors.map((instructor) => (
                                    <MenuItem key={instructor.id} value={instructor.id}>
                                        {instructor.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText error={!!errors.instructor}>{errors.instructor}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Duration (hours)"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                            disabled={!isEditing}
                            type="number"
                            error={!!errors.duration}
                            helperText={errors.duration}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Start Date"
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                            disabled={!isEditing}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.startDate}
                            helperText={errors.startDate}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="End Date"
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            fullWidth
                            margin="normal"
                            required
                            disabled={!isEditing}
                            InputLabelProps={{ shrink: true }}
                            error={!!errors.endDate}
                            helperText={errors.endDate}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth margin="normal" required>
                            <InputLabel>Status</InputLabel>
                            <Select
                                value={status}
                                onChange={(e) => setStatus(e.target.value)}
                                disabled={!isEditing}
                            >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                            </Select>
                            <FormHelperText error={!!errors.status}>{errors.status}</FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">Upload Course Image:</Typography>
                        <input
                            accept="image/*"
                            type="file"
                            onChange={handleImageChange}
                            disabled={!isEditing}
                        />
                        {imagePreview && (
                            <div style={{ marginTop: '10px' }}>
                                <img
                                    src={imagePreview}
                                    alt="Course Preview"
                                    style={{ width: '100%', maxWidth: '200px', height: 'auto' }}
                                />
                            </div>
                        )}
                    </Grid>

                    {isEditing && (
                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
                                Save
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </form>
        </div>
    );
};

export default CourseForm;
