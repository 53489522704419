import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  CircularProgress,
  Snackbar,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  Box
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useGetUsersQuery, useUpdateUserRoleMutation, useDeleteUserMutation } from '../../redux/services/api';

const Users = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    userId: null,
    newRole: null,
    userName: ''
  });
  const [updatingRole, setUpdatingRole] = useState(null);

  // Fetch users data using RTK Query
  const { data, isLoading, error } = useGetUsersQuery({
    page: page + 1, // Adjust page for 1-based index
    rowsPerPage,
    search: searchTerm,
  });

  // Mutation hooks for role update and user deletion
  const [updateUserRole] = useUpdateUserRoleMutation();
  const [deleteUser] = useDeleteUserMutation();

  // Handle page change for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Handle search input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle role change with confirmation
  const handleRoleChange = (id, name, currentRole, newRole) => {
    setConfirmDialog({
      open: true,
      userId: id,
      userName: name,
      newRole,
    });
  };

  const confirmRoleUpdate = async () => {
    const { userId, newRole } = confirmDialog;
    setConfirmDialog({ ...confirmDialog, open: false });
    setUpdatingRole(userId);

    try {
      await updateUserRole({ id: userId, newRole }).unwrap();
      setSuccessMessage('Role updated successfully!');

      // Update the role locally in the data object
      if (data?.users) {
        const userIndex = data.users.findIndex(user => user.id === userId);
        if (userIndex !== -1) {
          data.users[userIndex].role = newRole;
        }
      }
    } catch (error) {
      console.error('Failed to update role:', error);
      setErrorMessage('Failed to update role. Please try again.');
    } finally {
      setUpdatingRole(null);
    }
  };

  // Handle delete user confirmation
  const handleDeleteClick = (id) => {
    setSelectedUserId(id);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteUser(selectedUserId).unwrap();
      setSuccessMessage('User deleted successfully!');
    } catch (error) {
      console.error('Failed to delete user:', error);
      setErrorMessage('Failed to delete user. Please try again.');
    } finally {
      setOpenDialog(false);
    }
  };

  const getRoleName = (role) => {
    switch (role) {
      case 1: return 'Admin';
      case 2: return 'Teacher';
      case 3: return 'Student';
      default: return 'Unknown';
    }
  };

  return (
    <Box sm={{ padding: 3 }}>
      <TextField
        label="Search for users"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearch}
        fullWidth
        sx={{ mb: 3 }}
      />

      <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Table>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell><strong>ID</strong></TableCell>
                <TableCell><strong>Name</strong></TableCell>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Role</strong></TableCell>
                <TableCell align="center"><strong>Action</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.users?.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.id}</TableCell>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Select
                      value={user.role}
                      onChange={(e) => handleRoleChange(user.id, user.name, user.role, e.target.value)}
                      disabled={isLoading || updatingRole === user.id}
                      fullWidth
                      style={updatingRole === user.id ? { filter: 'blur(2px)' } : {}}
                    >
                      <MenuItem value={3}>Student</MenuItem>
                      <MenuItem value={2}>Teacher</MenuItem>
                      <MenuItem value={1}>Admin</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell align="center">
                    <IconButton onClick={() => handleDeleteClick(user.id)} disabled={isLoading} color="error" aria-label="delete user">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      <TablePagination
        component="div"
        count={data?.total || 0}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[10, 25, 50]}
      />

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        message={successMessage}
        action={
          <Button color="inherit" onClick={() => setSuccessMessage('')}>Close</Button>
        }
      />

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        message={errorMessage}
        action={
          <Button color="inherit" onClick={() => setErrorMessage('')}>Close</Button>
        }
      />

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="secondary" disabled={isLoading}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={confirmDialog.open}
        onClose={() => setConfirmDialog({ ...confirmDialog, open: false })}
      >
        <DialogTitle>Confirm Role Change</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the role of <strong>{confirmDialog.userName}</strong> to <strong>{getRoleName(confirmDialog.newRole)}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialog({ ...confirmDialog, open: false })} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmRoleUpdate} color="secondary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Users;
