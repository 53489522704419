import React from 'react';
import {
  Avatar,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Box,
  Divider,
  CircularProgress,
} from '@mui/material';
import { Edit, Security, Settings, Notifications } from '@mui/icons-material';
import { useFetchProfileQuery } from '../redux/services/api';

const AdminProfile = () => {
  
  const userId = localStorage.getItem('userId');
  const { data, isLoading, isError, error } = useFetchProfileQuery(userId);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography variant="h6" color="error">
          Failed to load profile data: {error?.message || 'Unknown Error'}
        </Typography>
      </Box>
    );
  }

  const { name, email, role } = data;

  return (
    <Box className="profile-container" style={{ padding: '20px' }}>
      <Card style={{ padding: '20px', borderRadius: '15px' }}>
        <Grid container spacing={3}>

          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Avatar src="/path/to/profile-pic.jpg" style={{ width: '100px', height: '100px', margin: 'auto' }} />
            <Typography variant="h4">{name}</Typography>
            <Typography variant="subtitle1">{`Role: ${role}`}</Typography>
            <Typography variant="subtitle2">{email}</Typography>
            <Button variant="outlined" startIcon={<Edit />}>Edit Profile</Button>
          </Grid>

          <Divider style={{ margin: '20px 0' }} />

          {/* <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h6">Quick Stats</Typography>
              {role === 'Admin' && (
                <>
                  <Typography>Total Users: {totalUsers}</Typography>
                  <Typography>Active Courses: {activeCourses}</Typography>
                  <Typography>Recent Logins: {recentLogins}</Typography>
                </>
              )}
              {role === 'Teacher' && (
                <>
                  <Typography>Assigned Courses: {assignedCourses}</Typography>
                </>
              )}
              {role === 'Student' && (
                <>
                  <Typography>Completed Courses: {completedCourses}</Typography>
                </>
              )}
            </CardContent>
          </Grid> */}

          <Grid item xs={12} md={6}>
            <CardContent>
              <Typography variant="h6">Security</Typography>
              <Button variant="outlined" startIcon={<Security />}>Update Password</Button>
              <Button variant="outlined" startIcon={<Security />}>Set Up 2FA</Button>
              <Button variant="outlined" startIcon={<Security />}>View Login Activity</Button>
            </CardContent>
          </Grid>

          <Divider style={{ margin: '20px 0' }} />

          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h6">Settings & Preferences</Typography>
              <Button variant="outlined" startIcon={<Settings />}>Theme Selection</Button>
              <Button variant="outlined" startIcon={<Notifications />}>Notification Settings</Button>
              <Button variant="outlined" startIcon={<Settings />}>Language & Locale</Button>
            </CardContent>
          </Grid>

          <Divider style={{ margin: '20px 0' }} />

          <Grid item xs={12}>
            <CardContent>
              <Typography variant="h6">Help & Support</Typography>
              <Button variant="outlined" startIcon={<Settings />}>Support Tickets</Button>
              <Button variant="outlined" startIcon={<Settings />}>Documentation</Button>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default AdminProfile;
