import React, { useState, useEffect } from 'react';
import { 
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
    Paper, CircularProgress, Snackbar, TablePagination, IconButton, 
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
    Button, TextField, Box 
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom'; // Import Link for routing
import { useFetchStudentsQuery, useDeleteStudentMutation } from '../../redux/services/api'; // Import Redux Query hooks

const Students = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');

    // Redux Query hook to fetch students
    const { data, error, isLoading, refetch } = useFetchStudentsQuery({
        page: page + 1,
        rowsPerPage,
        search: searchTerm
    });

    const [deleteStudent, { isLoading: isDeleting }] = useDeleteStudentMutation();

    const handleDeleteClick = (id) => {
        setSelectedStudentId(id);
        setOpenDialog(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await deleteStudent(selectedStudentId);
            setSuccessMessage('Student deleted successfully!');
            refetch(); // Refetch data to reflect the changes
        } catch (error) {
            console.error('Failed to delete student:', error);
        } finally {
            setOpenDialog(false);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    return (
        <Box sx={{ padding: 3 }}>
            <TextField
                variant="outlined"
                placeholder="Search for students"
                value={searchTerm}
                onChange={handleSearch}
                fullWidth
                sx={{ mb: 3 }}
            />

            <TableContainer component={Paper} sx={{ boxShadow: 3, borderRadius: 2 }}>
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableRow>
                                <TableCell><strong>ID</strong></TableCell>
                                <TableCell><strong>Name</strong></TableCell>
                                <TableCell><strong>Email</strong></TableCell>
                                <TableCell align="center"><strong>Action</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.students?.map((student) => (
                                <TableRow key={student.id} hover>
                                    <TableCell>{student.id}</TableCell>
                                    <TableCell>{student.name}</TableCell>
                                    <TableCell>{student.email}</TableCell>
                                    <TableCell align="center">
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            component={Link}
                                            to={`/admin/student/assign-courses/${student.id}`}
                                        >
                                            Assign Courses
                                        </Button>
                                        <IconButton 
                                            onClick={() => handleDeleteClick(student.id)} 
                                            color="error" 
                                            disabled={isDeleting}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
            
            <TablePagination
                component="div"
                count={data?.total || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ mt: 2 }}
            />
            
            <Snackbar
                open={!!successMessage}
                autoHideDuration={6000}
                onClose={() => setSuccessMessage('')}
                message={successMessage}
            />
            
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this student? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDeleteConfirm} color="secondary" disabled={isDeleting}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Students;
