import React, { useState } from 'react';
import { Box, Typography, Checkbox, Button, CircularProgress, Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    useFetchCoursesQuery,
    useFetchAssignedCoursesQuery,
    useAssignCoursesMutation,
} from '../../redux/services/api';

const AssignCourses = () => {
    const { studentId } = useParams();
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');

    // Fetch courses and assigned courses using Redux Query
    const { data: courses = [], isLoading: coursesLoading } = useFetchCoursesQuery();
    const {
        data: assignedCourses = [],
        isLoading: assignedLoading,
        refetch: refetchAssignedCourses,
    } = useFetchAssignedCoursesQuery(studentId);

    const [assignCourses, { isLoading: assignLoading }] = useAssignCoursesMutation();

    // Pre-select assigned courses when fetched
    React.useEffect(() => {
        setSelectedCourses(assignedCourses);
    }, [assignedCourses]);

    const handleCourseSelect = (courseId) => {
        if (selectedCourses.includes(courseId)) {
            setSelectedCourses(selectedCourses.filter((id) => id !== courseId));
        } else {
            setSelectedCourses([...selectedCourses, courseId]);
        }
    };

    const handleAssignCourses = async () => {
        try {
            await assignCourses({ studentId, courses: selectedCourses }).unwrap();
            setSuccessMessage('Courses assigned successfully!');
            refetchAssignedCourses();
        } catch (error) {
            console.error('Failed to assign courses:', error);
        }
    };

    const loading = coursesLoading || assignedLoading || assignLoading;

    return (
        <Box sx={{ padding: 4, backgroundColor: '#fafafa', borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
            <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                Assign Courses to Student
            </Typography>

            <Typography variant="h6" sx={{ mb: 2 }}>Available Courses</Typography>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 3 }}>
                    {/* Table Header */}
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '12px 16px',
                        backgroundColor: '#eeeeee',
                        fontWeight: 'bold',
                        borderRadius: '4px',
                        mb: 2
                    }}>
                        <Typography sx={{ flex: '0.1', textAlign: 'center' }}>#</Typography>
                        <Typography sx={{ flex: '0.5' }}>Course Name</Typography>
                        <Typography sx={{ flex: '0.3', textAlign: 'center' }}>Duration (in hours)</Typography>
                        <Typography sx={{ flex: '0.1', textAlign: 'center' }}>Assign</Typography>
                    </Box>

                    {/* Course Rows */}
                    {courses.map((course, index) => (
                        <Box key={course.id} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '12px 16px',
                            backgroundColor: '#ffffff',
                            borderRadius: '4px',
                            boxShadow: '0 1px 4px rgba(0, 0, 0, 0.1)',
                            transition: 'background-color 0.3s ease',
                            mb: 1,
                            '&:hover': {
                                backgroundColor: '#f5f5f5',
                            }
                        }}>
                            <Typography sx={{ flex: '0.1', textAlign: 'center' }}>{index + 1}</Typography>
                            <Typography sx={{ flex: '0.5' }}>{course.title}</Typography>
                            <Typography sx={{ flex: '0.3', textAlign: 'center' }}>{course.duration || 'N/A'}</Typography>
                            <Checkbox
                                checked={selectedCourses.includes(course.id)}
                                onChange={() => handleCourseSelect(course.id)}
                                sx={{ flex: '0.1', textAlign: 'center' }}
                            />
                        </Box>
                    ))}

                    {/* Optional Divider between sections */}
                    <Divider sx={{ my: 2 }} />
                </Box>
            )}

            <Button variant="contained" color="primary" onClick={handleAssignCourses} disabled={loading} sx={{ mt: 2, width: '200px' }}>
                Assign Selected Courses
            </Button>

            {successMessage && <Typography color="green" sx={{ mt: 2 }}>{successMessage}</Typography>}
        </Box>
    );
};

export default AssignCourses;
