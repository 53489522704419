import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { Typography, Box, Button, Card, CardContent, Grid, Paper, CircularProgress } from '@mui/material';
import { useGetTeacherStatsQuery } from '../../redux/services/api'; // Import the RTK Query hook
import { Bar, Pie } from 'react-chartjs-2';
import { Chart, BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement } from 'chart.js';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIcon from '@mui/icons-material/Assignment';

// Register Chart.js components
Chart.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend, ArcElement);

const TeacherDashboard = () => {
  const navigate = useNavigate();
  const teacherId = localStorage.getItem('teacherId');

  // Fetch statistics for the teacher using RTK Query hook
  const { data: stats, error, isLoading } = useGetTeacherStatsQuery(teacherId);

  // Provide default values if data is not available
  const totalAssignments = stats?.totalAssignments ?? 0;
  const completedAssignments = stats?.completedAssignments ?? 0;

  // Data for Bar chart
  const barData = {
    labels: ['Total Assignments', 'Completed Assignments'],
    datasets: [
      {
        label: 'Assignments Overview',
        data: [totalAssignments, completedAssignments],
        backgroundColor: ['#1e88e5', '#5e35b1'],
      },
    ],
  };

  // Data for Pie chart
  const pieData = {
    labels: ['Total Assignments', 'Completed Assignments'],
    datasets: [
      {
        label: 'Assignments Overview',
        data: [totalAssignments, completedAssignments],
        backgroundColor: ['#1e88e5', '#5e35b1'],
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
    },
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="60vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" color="red" sx={{ textAlign: 'center' }}>
          Failed to load statistics
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, padding: '20px', backgroundColor: '#ffffff', minHeight: '100vh' }}>
      <Typography variant="h4" gutterBottom sx={{ color: '#3f51b5', textAlign: 'center', fontWeight: 'bold' }}>
        📚 Teacher Dashboard
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'center', color: '#616161' }}>
        Manage your courses and students effectively!
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ backgroundColor: '#e3f2fd', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: '#1565c0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <SchoolIcon fontSize="large" sx={{ mr: 1 }} /> Total Courses
              </Typography>
              <Typography variant="h4" sx={{ color: '#1565c0' }}>
                {stats?.totalCourses}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ backgroundColor: '#fff3e0', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: '#ef6c00', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <AssignmentIcon fontSize="large" sx={{ mr: 1 }} /> Total Assignments
              </Typography>
              <Typography variant="h4" sx={{ color: '#ef6c00' }}>
                {totalAssignments}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ backgroundColor: '#ede7f6', borderRadius: '12px', textAlign: 'center', boxShadow: 1 }}>
            <CardContent>
              <Typography variant="h6" sx={{ color: '#5e35b1', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <EmojiEventsIcon fontSize="large" sx={{ mr: 1 }} /> Completed Assignments
              </Typography>
              <Typography variant="h4" sx={{ color: '#5e35b1' }}>
                {completedAssignments}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Add two charts side by side in a grid */}
      <Box mt={6}>
        <Typography variant="h6" sx={{ color: '#3f51b5', textAlign: 'center' }}>
          📊 Assignments Overview
        </Typography>
        <Grid container spacing={4} justifyContent="center" sx={{ mt: 2 }}>
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: '16px', borderRadius: '12px', boxShadow: 1, height: '400px' }}>
              <Bar data={barData} options={chartOptions} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ padding: '16px', borderRadius: '12px', boxShadow: 1, height: '400px' }}>
              <Pie data={pieData} options={chartOptions} />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      {/* Buttons for navigation */}
      <Box mt={6} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          sx={{ fontSize: '1.1rem', borderRadius: '30px', padding: '10px 30px', minWidth: '250px', marginRight: '20px' }}
          component={NavLink}
          to="/teacher/courses"
        >
          📚 View Courses
        </Button>
        <Button
          variant="contained"
          color="secondary"
          sx={{ fontSize: '1.1rem', borderRadius: '30px', padding: '10px 30px', minWidth: '250px' }}
          component={NavLink}
          to="/teacher/students"
        >
          👥 View Students
        </Button>
      </Box>
    </Box>
  );
};

export default TeacherDashboard;
