// roleSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  role: null,
  adminId: null,
  studentId: null,
  teacherId: null,
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setId: (state, action) => { 
      state.id = action.payload; 
      localStorage.setItem('userId', action.payload);
    },
    setRole: (state, action) => { 
      state.role = action.payload; 
      localStorage.setItem('userRole', action.payload);
    },
    setAdminId: (state, action) => { 
      state.adminId = action.payload; 
      localStorage.setItem('adminId', action.payload);
    },
    setStudentId: (state, action) => { 
      state.studentId = action.payload; 
      localStorage.setItem('studentId', action.payload);
    },
    setTeacherId: (state, action) => { 
      state.teacherId = action.payload; 
      localStorage.setItem('teacherId', action.payload);
    },
    resetUser: (state) => {
      Object.assign(state, initialState);
      // Clear local storage when resetting user
      localStorage.removeItem('userRole');
      localStorage.removeItem('userId');
      localStorage.removeItem('adminId');
      localStorage.removeItem('studentId');
      localStorage.removeItem('teacherId');
    },
  },
});

export const { setId, setRole, setAdminId, setStudentId, setTeacherId, resetUser } = roleSlice.actions;
export default roleSlice.reducer;
